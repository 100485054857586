import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { useParams, useLoaderData } from "react-router-dom"
import Hero from '../components/Hero';
import Navbar from '../components/Navbar';
import Match, { FinishedMatch } from '../components/Match';
import '../assets/css/phase.css'

export function loader({ params }) {
    return { pouleID: params.pouleID, tournamentID: params.tournamentid }
}
let TimeInfo
const HomeURL = window.location.origin.toString()
const MainURL = HomeURL.split(':')[0] + ":" + HomeURL.split(':')[1]

export default function TournamentPage() {
    const { pouleID, tournamentID } = useLoaderData()
    const [activeMatches, setActiveMatches] = useState([]);
    const [hasActiveMatches, setHasActiveMatches] = useState(false);
    const [finishedMatches, setFinishedMatches] = useState([]);
    const [hasFinishedMatches, setHasFinishedMatches] = useState(false);
    const [tournamentInfo, setTournamentInfo] = useState(null);

    const [pouleInformation, setPouleInformation] = useState([]);
    const [hasPouleInformation, setHasPouleInformation] = useState(false);
    const [Name, setName] = useState('');
    const [Start, setStart] = useState('');
    const [Finished, setFinished] = useState('');

    async function getInfo() {
        try {
            const pouleInformationResponse = await axios.get(`https://api.mormelmania.be/phase/poule/${pouleID}`);
            const pouleInformationList = Array.isArray(pouleInformationResponse.data) ?
                pouleInformationResponse.data.map(PouleElement => (
                    <a href={HomeURL + "/tournament/" + tournamentID + "/team/" + PouleElement.TeamID} >
                        <tr>
                            <td>{PouleElement.Name}</td>
                            <td>{PouleElement.GamesPlayed}</td>
                            <td>{PouleElement.RoundsWon}</td>
                            <td>{PouleElement.RoundsLost}</td>
                            <td>{PouleElement.RoundsWon - PouleElement.RoundsLost}</td>
                            <td>{PouleElement.Points}</td>
                        </tr>
                    </a>
                )) :
                [];
            setPouleInformation(pouleInformationList);
            setHasPouleInformation(pouleInformationList.length > 0);

            const finishedResponse = await axios.get(`https://api.mormelmania.be/phase/matches/${pouleID}/1`);
            const finishedMatchesList = Array.isArray(finishedResponse.data) ?
                finishedResponse.data.map(element => (
                    <FinishedMatch Fase={"Round: " + element.MatchOrder} Team1={element.Team1} Team2={element.Team2} Map={element.Map}
                        StartTime={element.StartTime} MatchID={element.MatchID} Stream={element.Stream}
                        Score1={element.Team1Score} Score2={element.Team2Score} Finished="0" TournamentID={tournamentID}/>
                )) :
                [<FinishedMatch Fase={"Round: " + finishedResponse.data.MatchOrder} Team1={finishedResponse.data.Team1}
                    Team2={finishedResponse.data.Team2} Map={finishedResponse.data.Map} TournamentID={tournamentID}
                    StartTime={finishedResponse.data.StartTime} MatchID={finishedResponse.data.MatchID}
                    Stream={finishedResponse.data.Stream} Score1={finishedResponse.data.Team1Score} Score2={finishedResponse.data.Team2Score} />];
            setFinishedMatches(finishedMatchesList);
            setHasFinishedMatches(finishedMatchesList.length > 0);

            const activeResponse = await axios.get(`https://api.mormelmania.be/phase/matches/${pouleID}/0`);

            const activeMatchesList = Array.isArray(activeResponse.data) ?
                activeResponse.data.map(response => (

                    <Match Fase={"Round: " + response.MatchOrder} Team1={response.Team1} Team2={response.Team2} Map={response.Map}
                        StartTime={response.StartTime} MatchID={response.MatchID} Stream={response.Stream} TournamentID={tournamentID}/>
                )) :
                [<Match Fase={"Round: " + activeResponse.data.MatchOrder} Team1={activeResponse.data.Team1}
                    Team2={activeResponse.data.Team2} Map={activeResponse.data.Map}
                    StartTime={activeResponse.data.StartTime} MatchID={activeResponse.data.MatchID}
                    Stream={activeResponse.data.Stream} TournamentID={tournamentID}/>];
            setActiveMatches(activeMatchesList);
            setHasActiveMatches(activeMatchesList.length > 0);

            const poule = await axios.get(`https://api.mormelmania.be/phase/info/${pouleID}`);
            setFinished(poule.data[0].Finished)
            setName(poule.data[0].Name)
            setStart(poule.data[0].StartTime)

            const TournamentResponse = await axios.get(`https://api.mormelmania.be/tournament/${tournamentID}`);
            if (TournamentResponse.data[0]) {
                return {
                    banner: TournamentResponse.data[0].BannerURL,
                    color: TournamentResponse.data[0].Color,
                    name: TournamentResponse.data[0].Name,
                    start: TournamentResponse.data[0].StartTime,
                    description: TournamentResponse.data[0].Description,
                    rules: TournamentResponse.data[0].Rules,
                    finished: TournamentResponse.data[0].Finished

                }
            }

        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {

        async function fetchTournamentInfo() {
            const tournament = await getInfo();
            setTournamentInfo(tournament);
        }
        const interval = setInterval(fetchTournamentInfo, 10000);

        fetchTournamentInfo();


    }, []);
    if (Finished == 1) {
        TimeInfo = "Finished"
    } else {
        TimeInfo = "Starting: " + Start
    }

    let LEFT = (<div className='LEFT'>
        <div className='Poule'>
            <table>
                <tr>
                    <th>Team</th>
                    <th>Played</th>
                    <th>W's</th>
                    <th>L's</th>
                    <th>Diff</th>
                    <th>Points</th>
                </tr>
                {pouleInformation}
            </table>
        </div>
    </div>)
    return (
        <React.StrictMode>
            <Navbar version="1" TourneyID={tournamentID} />
            {tournamentInfo &&
                <Hero Banner={tournamentInfo.banner} Color={tournamentInfo.color} Name={Name}
                    StartTime={TimeInfo} Logo="" />}
            <div className="Page">
                {LEFT}
                <div className='RIGHT'>

                    {hasActiveMatches && (
                        <>
                            <h1>Upcoming matches:</h1>
                            <div className='UPCOMING_MATCHES'>
                                {activeMatches}
                            </div>
                        </>
                    )}
                    {hasFinishedMatches && (
                        <>
                            <h1>Finished Matches:</h1>
                            <div className='UPCOMING_MATCHES'>
                                {finishedMatches}
                            </div>

                        </>
                    )}

                </div>
            </div>
        </React.StrictMode>
    )
}