import axios from "axios";
import React, { useState, useEffect } from 'react';
import '../assets/css/matches.css'

const HomeURL = window.location.origin.toString()
const MainURL = HomeURL.split(':')[0] + ":" + HomeURL.split(':')[1]

export default function Bracket() {
    return (<p>esf</p>)
}

export function SmallBracket(props) {

    const PhaseID = props.PhaseID;
    const TournamentID = props.TournamentID

    const [participants, setParticipants] = useState([]);
    const [hasParticipants, setHasParticipants] = useState(false);

    useEffect(() => {
        async function getInfo() {
            try {
                const response = await axios.get(`https://api.mormelmania.be/phase/bracket/small/${PhaseID}`);
                const participantList = Array.isArray(response.data) ? response.data.map(element => (
                    <div className='BRACKET_TEAMS' key={element.Id}>
                        {element.Name != null ? (
                            <p className='TeamName'>{element.Name}</p>
                        ) : <p className='TeamName'>TBD</p>
                        }

                    </div>
                )) :
                    [<div className='BRACKET_TEAMS' key={response.data.Id}>
                        {response.data.Name != null ? (
                            <p className='TeamName'>{response.data.Name}</p>
                        ) : <p className='TeamName'>No information yet</p>
                        }
                    </div>];
                setParticipants(participantList);
                setHasParticipants(participantList.length > 0);
            } catch (error) {
                console.error(error)
            }
        }
        getInfo();
    }, [PhaseID]);
    const URL = HomeURL + '/tournament/' + TournamentID +'/bracket/' + PhaseID
    return hasParticipants ? (<div className="Bracket" > <a href={URL}> <h3>{props.Name} </h3>{participants}</a> </div>) : (<p>No participants found</p>);
}
