import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements,
    HashRouter as Router,
    Route,
    Link
} from "react-router-dom"

import Tournaments from './pages/tournaments';
import ControllerTournaments from './controller/tournaments';
import Tournament, { loader as TournamentLoader } from "./pages/tournament"
import Phases, { loader as PhasesLoader } from "./pages/phases"
import Teams, { loader as TeamsLoader } from "./pages/teams"
import Poule, { loader as PouleLoader } from "./pages/poule"
import Team, { loader as TeamLoader } from "./pages/team"
import Match, { loader as MatchLoader } from "./pages/match"
import Bracket, { loader as BracketLoader } from "./pages/bracket"

import ControllerTournament, { loader as ControllerTournamentLoader } from "./controller/tournament"


const router = createBrowserRouter(createRoutesFromElements(
    <Route path="/">
        <Route index element={<Tournaments />} />
        <Route
            path="tournament/:tournamentid"
            element={<Tournament />}
            loader={TournamentLoader}
        />
        <Route
            path="tournament/:tournamentid/phases"
            element={<Phases />}
            loader={PhasesLoader}
        />
        <Route
            path="tournament/:tournamentid/poule/:pouleID"
            element={<Poule />}
            loader={PouleLoader}
        />
        <Route
            path="tournament/:tournamentid/bracket/:bracketID"
            element={<Bracket />}
            loader={BracketLoader}
        />

        <Route
            path="tournament/:tournamentid/teams"
            element={<Teams />}
            loader={TeamsLoader}
        />
        <Route
            path="tournament/:tournamentid/team/:teamid"
            element={<Team />}
            loader={TeamLoader}
        />
        <Route
            path="tournament/:tournamentid/match/:matchid"
            element={<Match />}
            loader={MatchLoader}
        />

        <Route
            path="controller/"
            element={<ControllerTournaments />}
        />
        <Route
            path="controller/tournament/:tournamentid"
            element={<ControllerTournament />}
            loader={ControllerTournamentLoader}
        />
    </Route>
))

function App() {
    return (
        <RouterProvider router={router} />
    )
}

ReactDOM
    .createRoot(document.getElementById('root'))
    .render(<App />);

