import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useParams, useLoaderData } from "react-router-dom"
import Hero from '../components/Hero';
import Navbar from '../components/Navbar';
import Match, { FinishedMatch } from '../components/Match';
import { SmallPoule } from '../components/Poule';
import { SmallBracket } from '../components/Bracket';
export function loader({ params }) {
    return (params.tournamentid)
}
let Start
const HomeURL = window.location.origin.toString()
const MainURL = HomeURL.split(':')[0] + ":" + HomeURL.split(':')[1]
export default function TournamentPage() {
    const ID = useLoaderData()
    const [activeMatches, setActiveMatches] = useState([]);
    const [hasActiveMatches, setHasActiveMatches] = useState(false);
    const [finishedMatches, setFinishedMatches] = useState([]);
    const [hasFinishedMatches, setHasFinishedMatches] = useState(false);
    const [tournamentInfo, setTournamentInfo] = useState(null);
    const [Poules, setPoules] = useState([]);
    const [hasPoules, setHasPoules] = useState(false);
    const [Brackets, setBrackets] = useState([]);
    const [hasBrackets, setHasBrackets] = useState(false);
    async function getInfo() {
        try {


            const finishedResponse = await axios.get(`https://api.mormelmania.be/matches/tournament/${ID}/1`);
            const finishedMatchesList = Array.isArray(finishedResponse.data) ?
                finishedResponse.data.map(element => (
                    <FinishedMatch Fase={element.Phase} Team1={element.Team1} Team2={element.Team2} Map={element.Map}
                        StartTime={element.StartTime} MatchID={element.MatchID} Stream={element.Stream} Score1={element.Team1Score} Score2={element.Team2Score} />
                )) :
                [<FinishedMatch Fase={finishedResponse.data.Phase} Team1={finishedResponse.data.Team1}
                    Team2={finishedResponse.data.Team2} Map={finishedResponse.data.Map}
                    StartTime={finishedResponse.data.StartTime} MatchID={finishedResponse.data.MatchID}
                    Stream={finishedResponse.data.Stream} Score1={finishedResponse.data.Team1Score} Score2={finishedResponse.data.Team2Score} />];
            setFinishedMatches(finishedMatchesList);
            setHasFinishedMatches(finishedMatchesList.length > 0);

            const activeResponse = await axios.get(`https://api.mormelmania.be/matches/tournament/${ID}/0`);

            const activeMatchesList = Array.isArray(activeResponse.data) ?
                activeResponse.data.map(response => (

                    <Match Fase={response.Phase} Team1={response.Team1} Team2={response.Team2} Map={response.Map}
                        StartTime={response.StartTime} MatchID={response.MatchID} Stream={response.Stream} />
                )) :
                [<Match Fase={activeResponse.data.Phase} Team1={activeResponse.data.Team1}
                    Team2={activeResponse.data.Team2} Map={activeResponse.data.Map}
                    StartTime={activeResponse.data.StartTime} MatchID={activeResponse.data.MatchID}
                    Stream={activeResponse.data.Stream} />];
            setActiveMatches(activeMatchesList);
            setHasActiveMatches(activeMatchesList.length > 0);

            const PoulesResponse = await axios.get(`https://api.mormelmania.be/poules/${ID}`);
            const PoulesList = Array.isArray(PoulesResponse.data) ?
                PoulesResponse.data.map(responsePoule => (
                    <SmallPoule PhaseID={responsePoule.PhaseID} Name={responsePoule.Name} TournamentID={ID} />
                )) :
                [<SmallPoule PhaseID={PoulesResponse.data[0].PhaseID} Name={PoulesResponse.data.Name} TournamentID={ID} />];

            setPoules(PoulesList);
            setHasPoules(PoulesList.length > 0)
            const BracketsResponse = await axios.get(`https://api.mormelmania.be/brackets/${ID}`);
            const BracketsList = Array.isArray(BracketsResponse.data) ?
                BracketsResponse.data.map(responseBracket => (
                    <SmallBracket PhaseID={responseBracket.PhaseID} Name={responseBracket.Name} TournamentID={ID} />
                )) :
                [<SmallBracket PhaseID={BracketsResponse.data[0].PhaseID} TournamentID={ID} Name={BracketsResponse.data[0].Name} />];
            setBrackets(BracketsList);
            setHasBrackets(BracketsList.length > 0)


            const TournamentResponse = await axios.get(`https://api.mormelmania.be/tournament/${ID}`);
            if (TournamentResponse.data[0]) {
                return {
                    banner: TournamentResponse.data[0].BannerURL,
                    color: TournamentResponse.data[0].Color,
                    name: TournamentResponse.data[0].Name,
                    start: TournamentResponse.data[0].StartTime,
                    description: TournamentResponse.data[0].Description,
                    rules: TournamentResponse.data[0].Rules,
                    finished: TournamentResponse.data[0].Finished

                }
            }

        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        async function fetchTournamentInfo() {
            const tournament = await getInfo();
            setTournamentInfo(tournament);
        }
        const interval = setInterval(fetchTournamentInfo, 10000);

        fetchTournamentInfo();
    }, []);
    if (tournamentInfo) {
        document.title = tournamentInfo.name + " - Phases";
        if (tournamentInfo.finished === 1) {
            Start = "Finished"
        } else {
            Start = "Starting: " + tournamentInfo.start
        }
    }
    return (
        <React.StrictMode>
            <Navbar version="1" TourneyID={ID} />
            {tournamentInfo &&
                <Hero Banner={tournamentInfo.banner} Color={tournamentInfo.color} Name={tournamentInfo.name}
                    StartTime={Start} Logo=""/>}
            <div className="Page">
                <div className="LEFT">
                    {hasPoules && (
                        <>
                            <h1>Poules: </h1>
                            <div className='Poules'>
                                {Poules}
                            </div>
                        </>
                    )}
                    {hasBrackets && (
                        <>
                            <h1>Playoffs: </h1>
                            <div className='Brackets'>
                                {Brackets}
                            </div>
                        </>
                    )}
                </div>
                <div className='RIGHT'>

                    {hasActiveMatches && (
                        <>
                            <h1>Upcoming matches:</h1>
                            <div className='UPCOMING_MATCHES'>
                                {activeMatches}
                            </div>
                        </>
                    )}
                    {hasFinishedMatches && (
                        <>
                            <h1>Finished Matches:</h1>
                            <div className='UPCOMING_MATCHES'>
                                {finishedMatches}
                            </div>
                        </>
                    )}

                </div>

            </div>
        </React.StrictMode>
    )
}