import Logo from '../assets/img/logo.svg'
import '../assets/css/hero.css'

var IMG

function Hero(props) {
    if (props.Logo != "") {
        IMG = props.Logo
    }else{
        IMG = Logo
    }
    return (
        <div className='HERO' style={{
            backgroundColor: props.Color,
            backgroundImage: 'linear-gradient(to top, rgba(245, 246, 252, 0.00), '+ props.Color +') ,' + 'URL(' + props.Banner + ')'
            
        }
        }>
            <img src={IMG}/>
            <h1>{props.Name}</h1>
            <p>{props.StartTime}</p>
        </div>
    )
}

export default Hero;