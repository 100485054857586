import '../assets/css/tournaments.css'

function Tournament(props) {
    const TournamentURL = "./tournament/" + props.ID
    return (
        <a href={TournamentURL}>
        <div class='Project' style={{
            backgroundColor: props.Color,
            backgroundImage: 'URL(' + props.IMG + ')'
        }}>
            
                <h2>{props.Name}</h2>
            
        </div>
        </a>
    )
}

export default Tournament;