import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useLoaderData } from "react-router-dom";
import Hero from '../components/Hero';
import Navbar from '../components/Navbar';
import Team from '../components/Team';
import '../assets/css/team.css';
import TeamInfo from '../components/teamInfo';

function TeamsPage() {
  const [members, setMembers] = useState([]);
  const [hasMembers, setHasMembers] = useState([]);
  const { matchID, tournamentID } = useLoaderData()
  const [tournamentInfo, setTournamentInfo] = useState(null);
  const [TeamNaam, setTeamNaam] = useState(null);
  const [TeamLogo, setTeamLogo] = useState(null);
  const [NextMatch, setNextMatch] = useState(null);

  const [activeMatches, setActiveMatches] = useState([]);
  const [hasActiveMatches, setHasActiveMatches] = useState(false);
  const [finishedMatches, setFinishedMatches] = useState([]);
  const [hasFinishedMatches, setHasFinishedMatches] = useState(false);


  let Start;
  const HomeURL = window.location.origin.toString()
  const MainURL = HomeURL.split(':')[0] + ":" + HomeURL.split(':')[1]

  async function getInfo() {
    try {
      const memberResponse = await axios.get(`https://api.mormelmania.be/match/${matchID}`);
      const TournamentResponse = await axios.get(`https://api.mormelmania.be/match/${matchID}`);
      if (TournamentResponse.data[0]) {
        return {
          banner: TournamentResponse.data[0].BannerURL,
          color: TournamentResponse.data[0].Color,
          name: TournamentResponse.data[0].TournamentName,
          start: TournamentResponse.data[0].StartTime,
          description: TournamentResponse.data[0].Description,
          rules: TournamentResponse.data[0].Rules,
          finished: TournamentResponse.data[0].Finished,
          stream: TournamentResponse.data[0].Stream,
          team1score: TournamentResponse.data[0].Team1Score,
          team2score: TournamentResponse.data[0].Team2Score,
          team1id: TournamentResponse.data[0].Team1ID,
          team2id: TournamentResponse.data[0].Team2ID,
          game: TournamentResponse.data[0].Game
        }
      }

    } catch (error) {
      console.error(error);
    }
  }


  useEffect(() => {

    async function fetchTournamentInfo() {
      const tournament = await getInfo();
      setTournamentInfo(tournament);
    }
    const interval = setInterval(fetchTournamentInfo, 10000);

    fetchTournamentInfo();


  }, []);

  if (tournamentInfo) {
    document.title = tournamentInfo.name + " - Phases";
    if (tournamentInfo.finished === 1) {
      Start = "Finished";
    } else {
      Start = "Starting: " + tournamentInfo.start;
    }
  }

  return (
    <React.StrictMode>
      <Navbar version="1" TourneyID={tournamentID} />
      {tournamentInfo && (
        <React.StrictMode>
        <Hero Banner={tournamentInfo.banner} Color={tournamentInfo.color} Name={tournamentInfo.name} StartTime={"EST: " + tournamentInfo.start} Logo="" />
        <div className="Page">
        <div className='LEFT'>
          <div className='MatchTeamInfo'>
            <TeamInfo id={tournamentInfo.team1id} tournamentID={tournamentID} score={tournamentInfo.team1score} game={tournamentInfo.game}/>
            <TeamInfo id={tournamentInfo.team2id} tournamentID={tournamentID} score={tournamentInfo.team2score}  game={tournamentInfo.game}/>
          </div>

        </div>

      </div>
      </React.StrictMode>
      )}
    </React.StrictMode>
  );
}

export function loader({ params }) {
  return { matchID: params.matchid, tournamentID: params.tournamentid }
}


export default TeamsPage;
