import axios from "axios";
import React, { useState, useEffect } from 'react';
import '../assets/css/matches.css'

const HomeURL = window.location.origin.toString()
const MainURL = HomeURL.split(':')[0] + ":" + HomeURL.split(':')[1]

export function SmallPoule(props) {

    const PhaseID = props.PhaseID;
    const TournamentID = props.TournamentID;

    const [participants, setParticipants] = useState([]);
    const [hasParticipants, setHasParticipants] = useState(false);

    useEffect(() => {
        async function getInfo() {
            try {
                const response = await axios.get(`https://api.mormelmania.be/phase/poule/small/${PhaseID}`);
                const participantList = Array.isArray(response.data) ? response.data.map(element => (
                        <div className='POULE_TEAMINFO' key={element.Id}>
                            <p className='TeamName'>{element.Name}</p>
                            <p className='TeamPoints'>{element.Points}</p>
                    </div>
                )) :
                    [<div className='POULE_TEAMINFO' key={response.data.Id}>
                        <p className='TeamName'>{response.data.Name}</p>
                        <p className='TeamPoints'>{response.data.Points}</p>
                    </div>];
                setParticipants(participantList);
                setHasParticipants(participantList.length > 0);
            } catch (error) {
                console.error(error)
            }
        }
        getInfo();
    }, [PhaseID]);
    const URL = HomeURL + '/tournament/' + TournamentID + '/poule/' + PhaseID
    return hasParticipants ? (<div className="SmallPoule"><a href={URL}> <h3>{props.Name} </h3>{participants}</a></div>) : (<p>No participants found</p>);
}
