import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { useParams, useLoaderData } from "react-router-dom"
import Hero from '../components/Hero';
import Navbar from '../components/Navbar';
import Match, { FinishedMatch } from '../components/Match';
import '../assets/css/phase.css'
import StreamIcon from "../assets/img/stream.png"
export function loader({ params }) {
    return { bracketID: params.bracketID, tournamentID: params.tournamentid }
}
let TimeInfo
const HomeURL = window.location.origin.toString()
const MainURL = HomeURL.split(':')[0] + ":" + HomeURL.split(':')[1]

export default function TournamentPage() {
    const { bracketID, tournamentID } = useLoaderData()
    const [matches, setMatches] = useState([]);
    const [hasMatches, setHasMatches] = useState(false);
    const [tournamentInfo, setTournamentInfo] = useState(null);
    const [Name, setName] = useState('');
    const [Start, setStart] = useState('');
    const [Finished, setFinished] = useState('');

    async function getInfo() {
        try {
    
            const matchesResponse = await axios.get(`https://api.mormelmania.be/phase/bracket/${bracketID}`);
            setMatches(matchesResponse.data);
            setHasMatches(true);
    
            const bracket = await axios.get(`https://api.mormelmania.be/phase/info/${bracketID}`);
            setFinished(bracket.data[0].Finished)
            setName(bracket.data[0].Name)
            setStart(bracket.data[0].StartTime)
            const TournamentResponse = await axios.get(`https://api.mormelmania.be/tournament/${tournamentID}`);
            if (TournamentResponse.data) {
                return {
                    banner: TournamentResponse.data[0].BannerURL,
                    color: TournamentResponse.data[0].Color,
                    start: TournamentResponse.data[0].StartTime,
                    description: TournamentResponse.data[0].Description,
                    rules: TournamentResponse.data[0].Rules,
                    finished: TournamentResponse.data[0].Finished
                };
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {

        async function fetchTournamentInfo() {
            const tournament = await getInfo();
            setTournamentInfo(tournament);
        }
        const interval = setInterval(fetchTournamentInfo, 10000);

        fetchTournamentInfo();


    }, []);
    if(Finished == 1){
        TimeInfo = "Finished"
    }else{
        TimeInfo = "Starting: " + Start
    }
    let previousMatchOrder = null;
    const rounds = {};
    matches.forEach((match) => {
      if (!rounds[match.MatchOrder]) {
        rounds[match.MatchOrder] = [];
      }
      rounds[match.MatchOrder].push(match);
    });
    
    // render each round with its matches
    let BRACKET = (
      <div className='BigPage'>
        {hasMatches ? (
          <div className='BigBracket'>
            {Object.keys(rounds).map((matchOrder) => (
              <div className="Round" id={matchOrder} key={matchOrder}>
                {rounds[matchOrder].map((match) => (
                  <FinishedMatch Fase={"Round: " + match.MatchOrder} Team1={match.Team1} Score1={match.Team1Score} 
                  Team2={match.Team2} Score2={match.Team2Score} Map={match.Map} StartTime={match.StartTime}
                  Stream={match.Stream} Finished={match.Finished} TournamentID={tournamentID} MatchID={match.MatchID}
                  />
                ))}
              </div>
            ))}
          </div>
        ) : (
          <p>No information to show!</p>
        )}
      </div>
    );
    return (
        <React.StrictMode>
            <Navbar version="1" TourneyID={tournamentID} />
            {tournamentInfo &&
                <Hero Banner={tournamentInfo.banner} Color={tournamentInfo.color} Name={Name}
                    StartTime={TimeInfo} Logo=""/>}
                {BRACKET}
        </React.StrictMode>
    )
}