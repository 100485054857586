import '../assets/css/navbar.css'
import Logo from '../assets/img/logo.svg'
var content
const MainURL = window.location.origin.toString()
function Navbar(props) {
    const id = props.TourneyID;
    const URLTournament = MainURL + '/tournament/' + id + "/";
    const URLMatches = MainURL + '/tournament/' + id + "/phases/";
    const URLTeams = MainURL + '/tournament/' + id + "/teams/";
    if (props.version == 1) {
        content = (
            <nav>
                <li><a href={URLTournament}>Information</a></li>
                <li><a href={URLMatches}>Matches</a></li>
                <li><a href={URLTeams}>Teams</a></li>
                <li><a href="https://www.twitch.tv/distinctesportevents" target="_blank">Stream</a></li>
            </nav>
        )
    } else {
        content = (
            <nav>
                <li><a href={MainURL}>Tournaments</a></li>
            </nav>
        )
    }
    return (
        
        <div className="NAV_BAR">
            <input className="checkbox" type="checkbox" name="" id="navmenu"/>
            <div className="hamburger-lines">
                <span className="line line1"></span>
                <span className="line line2"></span>
                <span className="line line3"></span>
            </div>
            <div className="NAV_BAR_LOGO">
                <a href={MainURL}>
                    <img className="LOGO" src={Logo}/>
                </a>
            </div>
            <div className="NAV_BAR_MENU">
                {content}
            </div>
        </div>
    )
}

export default Navbar;
