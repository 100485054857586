import StreamIcon from "../assets/img/stream.png"
import '../assets/css/matchbox.css'

const HomeURL = window.location.origin.toString()
export default function Match(props) {
    var Stream = ""
    if (props.Stream == 1) {
        var Stream = (<img class="STREAM" src={StreamIcon} />)
    }
    return (
        <a href={HomeURL + "/tournament/" + props.TournamentID + "/match/" + props.MatchID}>
            <div className="UPCOMING_MATCH">
            <h3>{props.Fase}</h3>
            {Stream}
            <div class="UPCOMING_MATCH_PARTICIPANTS">
                <h4>{props.Team1}</h4>
                <h4 class="vs"> VS </h4>
                <h4>{props.Team2}</h4>
            </div>
            <div class="UPCOMING_MATCH_INFORMATION">
                <p>Map: {props.Map}</p>
                <p>EST: {props.StartTime}</p>
            </div>
            </div>
        </a>
    )
}

export function FinishedMatch(props) {
    var Stream = ""
    if (props.Stream == 1) {
        var Stream = (<img class="STREAM" src={StreamIcon} />)
    }
    if (props.Score1 > props.Score2) {
        return (
            <a href={HomeURL + "/tournament/" + props.TournamentID + "/match/" + props.MatchID} >
            <div className="UPCOMING_MATCH">
                <h3>{props.Fase}</h3>
                {Stream}
                <div className="FINISHED_MATCH_SCORE">
                    <h4 class="TEAMNAME">{props.Team1}</h4>
                    <h4 id="WON" class="SCORE">{props.Score1}</h4>
                </div>
                <div className="FINISHED_MATCH_SCORE">
                    <h4 class="TEAMNAME">{props.Team2}</h4>
                    <h4 id="LOST" class="SCORE">{props.Score2}</h4>
                </div>
                <div className="UPCOMING_MATCH_INFORMATION">
                    <p>Map: {props.Map}</p>
                    {props.Finished == "0" ? <p>EST: {props.StartTime}</p> : <p>Game finished</p>}
                </div>

            </div>
            </a>
        )
    }else if(props.Score2 > props.Score1){
        return (
            <a href={HomeURL + "/tournament/" + props.TournamentID + "/match/" + props.MatchID} >
            <div className="UPCOMING_MATCH">
                <h3>{props.Fase}</h3>
                {Stream}
                <div className="FINISHED_MATCH_SCORE">
                    <h4 class="TEAMNAME">{props.Team1}</h4>
                    <h4 id="LOST" class="SCORE">{props.Score1}</h4>
                </div>
                <div className="FINISHED_MATCH_SCORE">
                    <h4 class="TEAMNAME">{props.Team2}</h4>
                    <h4 id="WON" class="SCORE">{props.Score2}</h4>
                </div>
                <div className="UPCOMING_MATCH_INFORMATION">
                    <p>Map: {props.Map}</p>
                    {props.Finished == "0" ? <p>EST: {props.StartTime}</p> : <p>Game finished</p>}
                </div>

            </div>
            </a>
        )

    }else{
        return (
            <a href={HomeURL + "/tournament/" + props.TournamentID + "/match/" + props.MatchID}>
            <div className="UPCOMING_MATCH">
                <h3>{props.Fase}</h3>
                {Stream}
                <div className="FINISHED_MATCH_SCORE">
                    <h4 class="TEAMNAME">{props.Team1}</h4>
                    <h4 id="TBD" class="SCORE">-</h4>
                </div>
                <div className="FINISHED_MATCH_SCORE">
                    <h4 class="TEAMNAME">{props.Team2}</h4>
                    <h4 id="TBD" class="SCORE">-</h4>
                </div>
                <div className="UPCOMING_MATCH_INFORMATION">
                    <p>Map: {props.Map}</p>
                    {props.Finished == "0" ? <p>EST: {props.StartTime}</p> : <p>Game finished</p>}
                </div>

            </div>
            </a>
        )
    }
}