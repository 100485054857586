import axios from "axios";
import React, { useState, useEffect } from 'react';
import '../assets/css/team.css'

const HomeURL = window.location.origin.toString()
const MainURL = HomeURL.split(':')[0] + ":" + HomeURL.split(':')[1]

export default function TeamInfo(props) {

    const TeamID = props.id;
    const TournamentID = props.tournamentID;
    const score = props.score;
    const game = props.game;
    const [TeamNaam, setTeamNaam] = useState(null);
    const [members, setMembers] = useState([]);
    const [hasMembers, setHasMembers] = useState([]);


    useEffect(() => {
        async function getInfo() {
            try {

                const memberResponse = await axios.get(`https://api.mormelmania.be/team/members/${TeamID}`);
                if (game == "Valorant") {
                    const memberList = Array.isArray(memberResponse.data) ?
                        memberResponse.data.map(MemberElement => (
                            <React.StrictMode>
                                <p className='TeamMember'>{MemberElement.Player}</p>
                                {MemberElement.RiotID != "" && MemberElement.RiotID != null ? (<p className='GameID'>{"RiotID: " + MemberElement.RiotID}</p>) : null}
                            </React.StrictMode>
                        )) :
                        [<React.StrictMode>
                            <p className='TeamMember'>{memberResponse.data[0].Player}</p>
                            {memberResponse.data[0].RiotID != "" ? (<p className='GameID'>{"RiotID: " + memberResponse.data[0].RiotID}</p>) : null}
                        </React.StrictMode>];
                    setMembers(memberList);
                    setHasMembers(memberList.length > 0);
                    setTeamNaam(memberResponse.data[0].TeamName)


                } else {
                    const memberList = Array.isArray(memberResponse.data) ?
                        memberResponse.data.map(MemberElement => (
                            <React.StrictMode>
                                <p className='TeamMember'>{MemberElement.Player}</p>
                            </React.StrictMode>
                        )) :
                        [<React.StrictMode>
                            <p className='TeamMember'>{memberResponse.data[0].Player}</p>
                        </React.StrictMode>];
                    setMembers(memberList);
                    setHasMembers(memberList.length > 0);
                    setTeamNaam(memberResponse.data[0].TeamName)
                }

            } catch (error) {
                console.error(error)
            }
        }
        getInfo();
    }, [TeamID]);
    return hasMembers ? (
        <a href={HomeURL + "/tournament/" + TournamentID + "/team/" + TeamID}>
            <h1>{TeamNaam + ": " + score}</h1>
            <div className="MatchTeamMembers">
            {members}
            </div>
        </a>
    ) : (
        <a href={HomeURL + "/tournament/" + TournamentID + "/team/" + TeamID}>
            <h1>{TeamNaam + ": " + score}</h1>
            <div className="MatchTeamMembers">
                <p className="Member">TBD</p>
            </div>
        </a>
    )
}
