import React, {useState, useEffect} from 'react';
import axios from "axios";
import Tournament from '../components/Tournament';
import Navbar from '../components/Navbar';
import '../assets/css/tournaments.css'

const HomeURL = window.location.origin.toString()
const MainURL = HomeURL.split(':')[0] + ":" + HomeURL.split(':')[1]
export default function TournamentsPage() {
    // Define state variables
    const [activeTournaments, setActiveTournaments] = useState([]);
    const [hasActiveTournaments, setHasActiveTournaments] = useState(false);
    const [finishedTournaments, setFinishedTournaments] = useState([]);

    // Call getTournaments() on component mount
    useEffect(() => {
        getTournaments();
        document.title = 'Tournaments';
    }, []);

    // Fetch both active and finished tournaments
    async function getTournaments() {
        try {
            const activeResponse = await axios.get(`https://api.mormelmania.be/tournaments/0`);
            if (activeResponse.data) {
                const activeTournaments = Array.isArray(activeResponse.data) ?
                    activeResponse.data.map(element => (
                        <Tournament Name={element.Name} Color={element.Color} IMG={element.BannerURL}
                                    ID={element.TournamentID}/>
                    )) :
                    [<Tournament Name={activeResponse.data.Name} Color={activeResponse.data.Color}
                                 IMG={activeResponse.data.BannerURL} ID={activeResponse.data.TournamentID}/>];
                setActiveTournaments(activeTournaments);
                setHasActiveTournaments(activeTournaments.length > 0);
            }


            const finishedResponse = await axios.get(`https://api.mormelmania.be/tournaments/1`);
            if (finishedResponse.data) {
                const finishedTournaments = Array.isArray(finishedResponse.data) ?
                    finishedResponse.data.map(element => (
                        <Tournament Name={element.Name} Color={element.Color} IMG={element.BannerURL}
                                    ID={element.TournamentID}/>
                    )) :
                    [<Tournament Name={finishedResponse.data.Name} Color={finishedResponse.data.Color}
                                 IMG={finishedResponse.data.BannerURL} ID={finishedResponse.data.TournamentID}/>];
                setFinishedTournaments(finishedTournaments);
            }
        } catch (error) {
            console.error(error);
        }
    }
    return (
        <React.StrictMode>
            <Navbar version="0"/>
            <div className="BigPage">
                {hasActiveTournaments && (
                    <>
                        <h1>Active tournaments:</h1>
                        <div className="Tournaments">
                            {activeTournaments}
                        </div>
                    </>
                )}
                {finishedTournaments.length > 0 && (
                    <>
                        <h1>Finished tournaments:</h1>
                        <div className="Tournaments">
                            {finishedTournaments}
                        </div>
                    </>
                )}
            </div>
        </React.StrictMode>
    )
}
