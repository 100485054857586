import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useLoaderData } from "react-router-dom";
import Hero from '../components/Hero';
import Navbar from '../components/Navbar';
import Team from '../components/Team';
import '../assets/css/team.css';
import Match, { FinishedMatch } from '../components/Match';

function TeamsPage() {
  const [members, setMembers] = useState([]);
  const [hasMembers, setHasMembers] = useState([]);
  const { teamID, tournamentID } = useLoaderData()
  const [tournamentInfo, setTournamentInfo] = useState(null);
  const [TeamNaam, setTeamNaam] = useState(null);
  const [TeamLogo, setTeamLogo] = useState(null);
  const [NextMatch, setNextMatch] = useState(null);

  const [activeMatches, setActiveMatches] = useState([]);
  const [hasActiveMatches, setHasActiveMatches] = useState(false);
  const [finishedMatches, setFinishedMatches] = useState([]);
  const [hasFinishedMatches, setHasFinishedMatches] = useState(false);


  let Start;
  const HomeURL = window.location.origin.toString()
  const MainURL = HomeURL.split(':')[0] + ":" + HomeURL.split(':')[1]

  async function getInfo() {
    try {
      const finishedResponse = await axios.get(`https://api.mormelmania.be/matches/team/${teamID}/1`);
      if (finishedResponse.data != []) {
        const finishedMatchesList = Array.isArray(finishedResponse.data) ?
          finishedResponse.data.map(element => (
            <FinishedMatch Fase={element.Phase} Team1={element.Team1} Team2={element.Team2} Map={element.Map}
              StartTime={element.StartTime} MatchID={element.MatchID} Stream={element.Stream}
              Score1={element.Team1Score} Score2={element.Team2Score} TournamentID={tournamentID} />
          )) :
          [<FinishedMatch Fase={finishedResponse.data[0].Phase} Team1={finishedResponse.data[0].Team1} Team2={finishedResponse.data[0].Team2} Map={finishedResponse.data[0].Map}
            StartTime={finishedResponse.data[0].StartTime} MatchID={finishedResponse.data[0].MatchID} Stream={finishedResponse.data[0].Stream}
            Score1={finishedResponse.data[0].Team1Score} Score2={finishedResponse.data[0].Team2Score} 
            TournamentID={tournamentID}/>];
        setFinishedMatches(finishedMatchesList);
        setHasFinishedMatches(finishedMatchesList.length > 0);
      }


      const activeResponse = await axios.get(`https://api.mormelmania.be/matches/team/${teamID}/0`);
      if (activeResponse.data.length != []) {
        const activeMatchesList = Array.isArray(activeResponse.data) ?
          activeResponse.data.map(response => (

            <Match Fase={response.Phase} Team1={response.Team1} Team2={response.Team2} Map={response.Map}
              StartTime={response.StartTime} MatchID={response.MatchID} Stream={response.Stream} TournamentID={tournamentID} />
          )) :
          [<Match Fase={activeResponse.data[0].Phase} Team1={activeResponse.data[0].Team1}
            Team2={activeResponse.data[0].Team2} Map={activeResponse.data[0].Map}
            StartTime={activeResponse.data[0].StartTime} MatchID={activeResponse.data[0].MatchID}
            Stream={activeResponse.data[0].Stream} TournamentID={tournamentID} />];
        setActiveMatches(activeMatchesList);
        setHasActiveMatches(activeMatchesList.length > 0);
      }

      const memberResponse = await axios.get(`https://api.mormelmania.be/team/members/${teamID}`);
      const TournamentResponse = await axios.get(`https://api.mormelmania.be/tournament/${tournamentID}`);


      if (TournamentResponse.data[0].Game == "Valorant") {
        const memberList = Array.isArray(memberResponse.data) ?
          memberResponse.data.map(MemberElement => (
            <React.StrictMode>
              <p className='TeamMember'>{MemberElement.Player}</p>
              {MemberElement.RiotID != "" ? (<p className='GameID'>{"RiotID: " + MemberElement.RiotID}</p>) : null}
            </React.StrictMode>
          )) :
          [<React.StrictMode>
            <p className='TeamMember'>{memberResponse.data[0].Player}</p>
            {memberResponse.data[0].RiotID != "" ? (<p className='GameID'>{"RiotID: " + memberResponse.data[0].RiotID}</p>) : null}
          </React.StrictMode>];
        setMembers(memberList);
        setHasMembers(memberList.length > 0);
        setTeamNaam(memberResponse.data[0].TeamName)
        setTeamLogo(memberResponse.data[0].LogoURL)
        if (memberResponse.data[0].StartTime != null) {
          setNextMatch("Next match: " + memberResponse.data[0].StartTime)
        } else {
          setNextMatch("Next match: TBD")
        }


      } else {
        const memberList = Array.isArray(memberResponse.data) ?
          memberResponse.data.map(MemberElement => (
            <React.StrictMode>
              <p className='TeamMember'>{MemberElement.Player}</p>
            </React.StrictMode>
          )) :
          [<React.StrictMode>
            <p className='TeamMember'>{memberResponse.data[0].Player}</p>
          </React.StrictMode>];
        setMembers(memberList);
        setHasMembers(memberList.length > 0);
        setTeamNaam(memberResponse.data[0].TeamName)
        setTeamLogo(memberResponse.data[0].LogoURL)
        if (memberResponse.data[0].StartTime != "") {
          setNextMatch("Next match: " + memberResponse.data[0].StartTime)
        } else {
          setNextMatch("Next match: TBD")
        }
      }



      if (TournamentResponse.data[0]) {
        return {
          banner: TournamentResponse.data[0].BannerURL,
          color: TournamentResponse.data[0].Color,
          name: TournamentResponse.data[0].Name,
          start: TournamentResponse.data[0].StartTime,
          description: TournamentResponse.data[0].Description,
          rules: TournamentResponse.data[0].Rules,
          finished: TournamentResponse.data[0].Finished
        }
      }

    } catch (error) {
      console.error(error);
    }
  }


  useEffect(() => {

    async function fetchTournamentInfo() {
      const tournament = await getInfo();
      setTournamentInfo(tournament);
    }
    const interval = setInterval(fetchTournamentInfo, 10000);

    fetchTournamentInfo();


  }, []);

  if (tournamentInfo) {
    document.title = tournamentInfo.name + " - Phases";
    if (tournamentInfo.finished === 1) {
      Start = "Finished";
    } else {
      Start = "Starting: " + tournamentInfo.start;
    }
  }

  return (
    <React.StrictMode>
      <Navbar version="1" TourneyID={tournamentID} />
      {tournamentInfo && (
        <Hero Banner={tournamentInfo.banner} Color={tournamentInfo.color} Name={TeamNaam} StartTime={NextMatch} Logo={TeamLogo} />
      )}
      <div className="Page">
        <div className='LEFT'>
          <div className='TeamMemberList'>
            <h1>Team members: </h1>
            {members}
          </div>

        </div>
        <div className='RIGHT'>
          {hasActiveMatches && (
            <>
              <h1>Upcoming matches:</h1>
              <div className='UPCOMING_MATCHES'>
                {activeMatches}
              </div>
            </>
          )}
          {hasFinishedMatches && (
            <>
              <h1>Finished Matches:</h1>
              <div className='UPCOMING_MATCHES'>
                {finishedMatches}
              </div>
            </>
          )}

        </div>

      </div>
    </React.StrictMode>
  );
}

export function loader({ params }) {
  return { teamID: params.teamid, tournamentID: params.tournamentid }
}


export default TeamsPage;
