import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useLoaderData } from "react-router-dom";
import Hero from '../components/Hero';
import Navbar from '../components/Navbar';
import Team from '../components/Team';
import '../assets/css/teams.css';

function TeamsPage() {
  const [teams, setTeams] = useState([]);
  const ID = useLoaderData();
  const [tournamentInfo, setTournamentInfo] = useState(null);
  let Start;
  const HomeURL = window.location.origin.toString()
  const MainURL = HomeURL.split(':')[0] + ":" + HomeURL.split(':')[1]

  async function getInfo() {
    try {
      const teamsResponse = await axios.get(`https://api.mormelmania.be/teams/${ID}`);
      const teamsList = Array.isArray(teamsResponse.data)
        ? teamsResponse.data.map(element => <Team id={element.TeamID} tournamentID={ID}/>)
        : [<Team id={teamsResponse.data.TeamID} tournamentID={ID}/>];
      setTeams(teamsList);

      const TournamentResponse = await axios.get(`https://api.mormelmania.be/tournament/${ID}`);
      if (TournamentResponse.data[0]) {
          return {
              banner: TournamentResponse.data[0].BannerURL,
              color: TournamentResponse.data[0].Color,
              name: TournamentResponse.data[0].Name,
              start: TournamentResponse.data[0].StartTime,
              description: TournamentResponse.data[0].Description,
              rules: TournamentResponse.data[0].Rules,
              finished: TournamentResponse.data[0].Finished

          }
      }

  }catch (error) {
      console.error(error);
    }
  }


  useEffect(() => {

    async function fetchTournamentInfo() {
      const tournament = await getInfo();
      setTournamentInfo(tournament);
    }
    const interval = setInterval(fetchTournamentInfo, 10000);

    fetchTournamentInfo();


  }, []);

  if (tournamentInfo) {
    document.title = tournamentInfo.name + " - Phases";
    if (tournamentInfo.finished === 1) {
      Start = "Finished";
    } else {
      Start = "Starting: " + tournamentInfo.start;
    }
  }

  return (
    <React.StrictMode>
      <Navbar version="1" TourneyID={ID} />
      {tournamentInfo && (
        <Hero Banner={tournamentInfo.banner} Color={tournamentInfo.color} Name={tournamentInfo.name} StartTime={Start} Logo="" />
      )}
      <div className="PageCenter">
        <div className='Teams'>
          {teams}
        </div>
      </div>
    </React.StrictMode>
  );
}

export function loader({ params }) {
  return params.tournamentid;
}

export default TeamsPage;
