import React, { useState, useEffect } from 'react';
import axios from 'axios';

const HomeURL = window.location.origin.toString();
const MainURL = HomeURL.split(':')[0] + ':' + HomeURL.split(':')[1];

export default function Team(props) {
    const [participants, setParticipants] = useState([]);


    const ID = props.id
    const TournamentID = props.tournamentID

    useEffect(() => {
        async function getInfo() {
            try {
                const response = await axios.get(`https://api.mormelmania.be/team/members/${ID}`);
                const participantList = response.data.map((element) => ({
                    player: element.Player,
                    logo: element.LogoURL,
                    teamNaam: element.TeamName,
                    
                }));
                setParticipants(participantList);
            } catch (error) {
                console.error(error);
            }
        }
        getInfo();
    }, [ID]); // add the missing dependency here

    return (
        <div className="Team">
            <a href={HomeURL + "/tournament/" + TournamentID + "/team/" + ID}>
            <h2 className="TeamNaam">{participants.length > 0 ? participants[0].teamNaam : ''}</h2>
            <img className="TeamLogo" src={participants.length > 0 ? participants[0].logo : ''} />
            <div className='Participants'>
            {participants.map((participant) => (
                <React.StrictMode>
                <p className="Speler">{participant.player}</p> 
                <br />
                </React.StrictMode>
            ))}
            </div>
            </a>
        </div>
    );
}
