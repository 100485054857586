import React, { useState, useEffect } from 'react';
import axios from "axios";
import Tournament from '../components/Tournament';
import Navbar from '../components/Navbar';
import '../assets/css/tournaments.css'
import '../assets/css/controller_tournaments.css'
import { redirect } from "react-router-dom";

const HomeURL = window.location.origin.toString()
const MainURL = HomeURL.split(':')[0] + ":" + HomeURL.split(':')[1]
export default function TournamentsPage() {
    // Define state variables
    const [activeTournaments, setActiveTournaments] = useState([]);
    const [hasActiveTournaments, setHasActiveTournaments] = useState(false);
    const [finishedTournaments, setFinishedTournaments] = useState([]);
    const [archivedTournaments, setArchivedTournaments] = useState([]);

    // Call getTournaments() on component mount
    useEffect(() => {
        getTournaments();
        document.title = 'Tournaments';
    }, []);

    // Fetch both active and finished tournaments
    async function getTournaments() {
        try {
            const loggedInResponse = await axios.get(`https://controlapi.mormelmania.be/check/login`, { withCredentials: true });
            if(loggedInResponse.data.isLoggedIn != true){
                window.location.replace("https://controlapi.mormelmania.be/login");
                return false;
                
            }




            const activeResponse = await axios.get(`https://api.mormelmania.be/tournaments/0`);
            if (activeResponse.data) {
                const activeTournaments = Array.isArray(activeResponse.data) ?
                    activeResponse.data.map(element => (
                        <Tournament Name={element.Name} Color={element.Color} IMG={element.BannerURL}
                            ID={element.TournamentID} />
                    )) :
                    [<Tournament Name={activeResponse.data.Name} Color={activeResponse.data.Color}
                        IMG={activeResponse.data.BannerURL} ID={activeResponse.data.TournamentID} />];
                setActiveTournaments(activeTournaments);
                setHasActiveTournaments(activeTournaments.length > 0);
            }
            const archivedResponse = await axios.get(`https://api.mormelmania.be/tournaments/2`);
            console.log(archivedResponse.data)
            if (archivedResponse.data) {
              const archivedTournaments = Array.isArray(archivedResponse.data) ?
                archivedResponse.data.map(elemento => (
                  <Tournament Name={elemento.Name} Color={elemento.Color} IMG={elemento.BannerURL}
                    ID={elemento.TournamentID} />
                )) :
                [<Tournament Name={archivedResponse.data.Name} Color={archivedResponse.data.Color}
                  IMG={archivedResponse.data.BannerURL} ID={archivedResponse.data.TournamentID} />];
              setArchivedTournaments(archivedTournaments);
              console.log("AAAA")
              console.log(archivedTournaments)
            }


            const finishedResponse = await axios.get(`https://api.mormelmania.be/tournaments/1`);
            if (finishedResponse.data) {
                const finishedTournaments = Array.isArray(finishedResponse.data) ?
                    finishedResponse.data.map(element => (
                        <Tournament Name={element.Name} Color={element.Color} IMG={element.BannerURL}
                            ID={element.TournamentID} />
                    )) :
                    [<Tournament Name={finishedResponse.data.Name} Color={finishedResponse.data.Color}
                        IMG={finishedResponse.data.BannerURL} ID={finishedResponse.data.TournamentID} />];
                setFinishedTournaments(finishedTournaments);
            }
        } catch (error) {
            console.error(error);
            
        }
    }
    return (
        <React.StrictMode>
            <Navbar version="0" />
            <div className="Page">
                <div className='Left'>
                    {hasActiveTournaments && (
                        <>
                            <h1>Active tournaments:</h1>
                            <div className="Tournaments">
                                {activeTournaments}
                            </div>
                        </>
                    )}
                    {finishedTournaments.length > 0 && (
                        <>
                            <h1>Finished tournaments:</h1>
                            <div className="Tournaments">
                                {finishedTournaments}
                            </div>
                        </>
                    )}
                    {archivedTournaments.length > 0 && (
                        <>
                            <h1>Archived tournaments:</h1>
                            <div className="Tournaments">
                                {archivedTournaments}
                                {console.log(archivedTournaments)}
                            </div>
                        </>
                    )}
                </div>
                <div className='Right'>
                    <div className='CreateTournament'>
                        <h3>Create tournament: </h3>
                        <form className='TournamentCreation' action="https://controlapi.mormelmania.be/create_tournament.php" method='POST'>
                            <input type='text' placeholder='Tournament name' name="name" required />
                            <textarea name='description' placeholder='Description' />
                            <textarea name='rules' placeholder='Rules' />
                            <input type='text' name="game" placeholder='Game' required />
                            <input type='text' name="banner" placeholder='BannerURL' required />
                            <input type='text' name='color' placeholder='Color' required />
                            <input type='number' name='teams' placeholder='Amount of teams' required/>
                            <button type='submit'>Create tournament</button>
                        </form>
                    </div>
                </div>
            </div>
        </React.StrictMode>
    )
}
